import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Do you want to help the FullCalendar project move forward? FullCalendar has become rather popular over the past few years and there is a lot to be done.
Any time and effort you are willing to contribute is greatly appreciated! Here are the ways you can contribute:`}</p>
    <h2>{`Answering StackOverflow Questions`}</h2>
    <p>{`FullCalendar has a lot of configuration options and some complex behavior, and often times people need help achieving what they want.
FullCalendar uses StackOverflow as a support forum. If you have experience with FullCalendar, answering other peoples' questions is a great way to contribute.`}</p>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "http://stackoverflow.com/questions/tagged/fullcalendar"
      }}>{`StackOverflow `}<strong parentName="a">{`fullcalendar`}</strong>{` tag`}</a></p>
    <h2>{`Issue Tracker Triage`}</h2>
    <p>{`The issue tracker gets submissions that are often not very useful in their initial form. They need to be fleshed out in some way:`}</p>
    <ul>
      <li parentName="ul">{`A bug that is not fully reproducable. The submitter needs help following the `}<Link to='/reporting-bugs' mdxType="Link">{`bug report instructions`}</Link>{`.`}</li>
      <li parentName="ul">{`A feature request that is not sufficiently described, according to the `}<Link to='/requesting-features' mdxType="Link">{`feature request instructions`}</Link>{`.`}</li>
      <li parentName="ul">{`A report that already exists and should be marked as "Duplicate". This is not as easy as it sounds, as there are hundreds of issues!`}</li>
      <li parentName="ul">{`An issue that is not really an issue and should be marked as "Invalid". Example: a question that should be asked on StackOverflow instead.`}</li>
    </ul>
    <p>{`The goal is to make every issue `}<em parentName="p">{`actionable`}</em>{`, so that its need is established and its implementation clear.`}</p>
    <p>{`If you know what you're doing with issue triage, and would like to contribute, please `}<a parentName="p" {...{
        "href": "http://arshaw.com/contact/"
      }}>{`contact me`}</a>{` and
I'll set you up with the correct permissions.`}</p>
    <h2>{`Writing Code`}</h2>
    <p>{`The project welcomes bugfixes and features.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/fullcalendar/fullcalendar/blob/master/CONTRIBUTING.md"
      }}>{`Instructions for contributing code `}{`»`}</a></p>
    <h2>{`Improving the Docs`}</h2>
    <p>{`The documentation needs some love, especially with providing examples. An official process is not yet in place,
but if you are interested in helping at some point, please `}<a parentName="p" {...{
        "href": "http://arshaw.com/contact/"
      }}>{`contact me`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      